import {
	DimensionElement,
	ShowroomArtworkDimensions,
} from '../models/showroom/showroom-artwork/data-section';
import { Languages } from '../models/globals';

function creteDimensionRow(element: DimensionElement): string {
	if (element.width && element.height && element.depth) {
		return `${element.height} x ${element.width} x ${element.depth} ${
			element.unit.labels[Languages.en]
		} (H x W x D)`;
	}

	if (element.width && element.height) {
		return `${element.height} x ${element.width} ${
			element.unit.labels[Languages.en]
		} (H x W)`;
	}

	if (element.width && element.depth) {
		return `${element.width} x ${element.depth} ${
			element.unit.labels[Languages.en]
		} (W x D)`;
	}

	if (element.depth && element.height) {
		return `${element.depth} x ${element.height} ${
			element.unit.labels[Languages.en]
		} (H x D)`;
	}

	if (element.width) {
		return `${element.width} ${element.unit.labels[Languages.en]} (W)`;
	}

	if (element.height) {
		return `${element.height} ${element.unit.labels[Languages.en]} (H)`;
	}

	if (element.depth) {
		return `${element.depth} ${element.unit.labels[Languages.en]} (D)`;
	}

	console.warn('Wrong dimensions');
	return '';
}

export function getArtworkDimensions(
	dimensions: ShowroomArtworkDimensions
): string[] {
	const dimensionsDesc: string[] = [];
	if (dimensions.unframed) {
		dimensionsDesc.push(`${creteDimensionRow(dimensions.unframed)}`);
	}

	if (dimensions.framed) {
		// NOTE: if unframed hasn't been set framed is simplest dimensions
		dimensionsDesc.push(`Framed: ${creteDimensionRow(dimensions.framed)}`);
	}

	if (dimensions.notes) {
		dimensionsDesc.push(dimensions.notes);
	}

	return dimensionsDesc;
}
