import React from 'react';
import { artworkContaineMeasurements } from '../../../utils';
import { componentStyle as cs } from './styles';
import { Props } from './models';
import { textColor } from '../../../../../styles';

export function ArtworkMeasurements(props: Props) {
	function getHeight(height: string | undefined, heightUnitLabel: string) {
		return height ? (
			<span>
				{height}
				{heightUnitLabel}
			</span>
		) : null;
	}

	function getWidth(width: string | undefined, widthUnitLabel: string) {
		return width ? (
			<span>
				{width}
				{widthUnitLabel}
			</span>
		) : null;
	}

	const height = getHeight(
		props.artwork.height,
		(props.artwork.heightUnit &&
			props.artwork.heightUnit.labels &&
			props.artwork.heightUnit.labels['en']) ||
			''
	);

	const width = getWidth(
		props.artwork.width,
		(props.artwork.widthUnit &&
			props.artwork.widthUnit.labels &&
			props.artwork.widthUnit.labels['en']) ||
			''
	);

	return artworkContaineMeasurements(props.artwork) ? (
		<div style={cs.wrapper}>
			{height}

			{height && width ? ' x ' : null}

			{width}
			<span style={{ color: textColor }}>
				{' '}
				{`(${height ? 'H' : ''}${height && width ? ' x ' : ''}${
					width ? 'W' : ''
				})`}
			</span>
		</div>
	) : null;
}
