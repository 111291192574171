import { FontStyle } from '../../../../../../ads-components/models';
import { textColor } from '../../../../../../styles';

export const componentStyle = {
	wrapper: {
		fontSize: '1.4rem',
		marginBottom: '4vh',
	},
	missingMeasurementsIndicator: {
		fontStyle: FontStyle.Italic,
		color: textColor,
	},
};
